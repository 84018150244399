export const BACKGROUND_COLORS = [
  'F7F9FC',
  'EEEDFD',
  'FFEBEE',
  'FDEFE2',
  'E7F9F3',
  'EDEEFD',
  'ECFAFE',
  'F2FFD1',
  'FFF7E0',
  'FDF1F7',
  'EAEFE6',
  'E0E6EB',
  'E4E2F3',
  'E6DFEC',
  'E2F4E8',
  'E6EBEF',
  'EBE6EF',
  'E8DEF6',
  'D8E8F3',
  'ECE1FE'
]

export const TEXT_COLORS = [
  '060A23',
  '4409B9',
  'BD0F2C',
  'C56511',
  '216E55',
  '05128A',
  '1F84A3',
  '526E0C',
  '935F10',
  '973562',
  '69785E',
  '2D3A46',
  '280F6D',
  '37364F',
  '363548',
  '4D176E',
  'AB133E',
  '420790',
  '222A54',
  '192251'
]

export const SHAPE_COLORS = [
  '060A23',
  '5E36F5',
  'E11234',
  'E87917',
  '3EA884',
  '0618BC',
  '0FBBE6',
  '87B80A',
  'FFC933',
  'EE77AF',
  '69785E',
  '2D3A46',
  '280F6D',
  '37364F',
  '363548',
  '4D176E',
  'AB133E',
  '420790',
  '222A54',
  '192251'
]